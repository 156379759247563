import {AccountSettingsId} from '@common/auth/ui/account-settings/account-settings-sidenav';
import {FormImageSelector} from '@common/uploads/components/image-selector';
import {FileUploadProvider} from '@common/uploads/uploader/file-upload-provider';
import {Button} from '@ui/buttons/button';
import {Form} from '@ui/forms/form';
import {FormTextField} from '@ui/forms/input-field/text-field/text-field';
import {Trans} from '@ui/i18n/trans';
import {AlternateEmailIcon} from '@ui/icons/material/AlternateEmail';
import {User} from '@ui/types/user';
import {useId} from 'react';
import {useForm} from 'react-hook-form';
import {AccountSettingsPanel} from '../account-settings-panel';
import {useRemoveAvatar} from '../avatar/remove-avatar';
import {useUploadAvatar} from '../avatar/upload-avatar';
import {useUpdateAccountDetails} from './update-account-details';

interface Props {
  user: User;
}
export function BasicInfoPanel({user}: Props) {
  const uploadAvatar = useUploadAvatar({user});
  const removeAvatar = useRemoveAvatar({user});
  const formId = useId();
  const form = useForm<Partial<Omit<User, 'subscriptions'>>>({
    defaultValues: {
      name: user.name || '',
      image: user.image,
    },
  });
  const updateDetails = useUpdateAccountDetails(user.id, form);

  return (
    <AccountSettingsPanel
      id={AccountSettingsId.AccountDetails}
      title={<Trans message="Update name and profile image" />}
      actions={
        <Button
          type="submit"
          variant="flat"
          color="primary"
          form={formId}
          disabled={updateDetails.isPending || !form.formState.isValid}
        >
          <Trans message="Save" />
        </Button>
      }
    >
      <Form
        form={form}
        className="flex flex-col items-center gap-40 md:flex-row md:gap-60"
        onSubmit={newDetails => {
          updateDetails.mutate(newDetails);
        }}
        id={formId}
      >
        <FileUploadProvider>
          <FormImageSelector
            variant="avatar"
            previewSize="w-90 h-90"
            showRemoveButton
            name="image"
            diskPrefix="avatars"
            onChange={url => {
              if (url) {
                uploadAvatar.mutate({url});
              } else {
                removeAvatar.mutate();
              }
            }}
          />
        </FileUploadProvider>
        <div className="w-full flex-auto">
          <div className="mb-16 flex items-center gap-4 text-muted">
            <AlternateEmailIcon size="sm" />
            {user.email}
          </div>
          <FormTextField name="name" label={<Trans message="Name" />} />
        </div>
      </Form>
    </AccountSettingsPanel>
  );
}
